<template>
    <div class="form-page">
        <div class="title-form">
            {{ this.$route.params.id ? $t('access.edit_user') : $t('access.create_user') }}
        </div>
        <div class="description">
            {{ $t('access.basic_info') }}
        </div>
        <div class="form-actions">
            <v-select v-if="userRender" :placeholder="$t('access.role')" :items="Object.values(roles)" v-model="userRender.type" item-text="name" item-value="type" class="select">
                <template slot="selection" slot-scope="data">
                    {{ $t(data.item.name) }}
                </template>
                <template slot="item" slot-scope="data">
                    {{ $t(data.item.name) }}
                </template>
            </v-select>
            <div class="row-error" v-if="errors.name">
                <Message type="error" :msg="$t(errors.name)"></Message>
            </div>
            <input type="text" :placeholder="$t('access.name')" v-model="userRender.name" :class="{ error: errors.name }" class="input-text" autocomplete="off" name="name" />
            <div class="row-error" v-if="errors.email">
                <Message type="error" :msg="$t(errors.email)"></Message>
            </div>
            <input type="text" :placeholder="$t('access.email')" v-model="userRender.email" :class="{ error: errors.email, disabled: userRender.id != 'tmpitw' }" class="input-text" autocomplete="off" name="name" :disabled="userRender.id != 'tmpitw'" />
            <div class="row-error" v-if="errors.restaurants">
                <Message type="error" :msg="$t(errors.restaurants)"></Message>
            </div>
        </div>
        <div class="data-table" :class="[{ 'with-errors': errors.email && errors.name }, { 'with-error': errors.email || errors.name }]">
            <v-data-table :ref="'accessDataSelected'" @toggle-select-all="allSelected = !allSelected" v-if="items" :value="selected" :headers="headers" disable-pagination fixed-header :search="search" :items="Object.values(items)" sort-by="name" :single-select="singleSelect" :sort-asc="true" hide-default-footer show-select>
                <template #item.code="item">
                    <div class="ticket">{{ item.item.code }}</div>
                </template>
                <template #item.name="item">
                    <div class="row">{{ item.item.name }}</div>
                </template>
                <template v-slot:no-data>
                    <div class="no-data">
                        {{ $t('bills.table_no_data') }}
                    </div>
                </template>
            </v-data-table>
        </div>
        <div class="action-buttons">
            <div class="export-button" @click="saveUser()">
                {{ id ? $t('access.save_changes') : $t('access_create_user') }}
            </div>
            <div class="cancel" @click="goBack()">
                {{ $t('access.turn_back') }}
            </div>
        </div>
    </div>
</template>

<script>
import Message from '../../../ui/Message.vue'
export default {
    components: { Message },
    name: 'AccessForm',
    props: { id: undefined },
    data() {
        return {
            search: '',
            allSelected: false,
            singleSelect: false,
            valueFilter: 1,
            selected: [],
            selectedLocations: [],
            idUser: false,
            sendForm: true,
            sortBy: 'selected',
            errors: {
                name: false,
                email: false,
                type: false,
                restaurants: false
            }
        }
    },
    computed: {
        items() {
            const restaurants = this.$store.getters['restaurant/getRestaurants']()
            const validRestaurants = {}
            const now = this.moment()

            for (const restaurant of Object.values(restaurants)) {
                let validRestaurant = true

                if (restaurant.start_date && restaurant.end_date) {
                    const startDate = this.moment(restaurant.start_date)
                    const endDate = this.moment(restaurant.end_date)
                    validRestaurant = this.moment(now).isBetween(startDate, endDate)
                }

                if (validRestaurant) {
                    validRestaurants[restaurant.id] = restaurant
                }
            }
            return validRestaurants
        },
        roles() {
            return [
                // {
                //     type: 1,
                //     name: this.$t('access_one')
                // },
                // {
                //     type: 2,
                //     name: this.$t('access_two')
                // },
                {
                    type: 3,
                    name: this.$t('access_three')
                },
                {
                    type: 4,
                    name: this.$t('access_four')
                }
            ]
        },
        headers() {
            return [
                {
                    text: this.$t('restaurants.number_site'),
                    align: 'start',
                    sortable: true,
                    value: 'code',
                    width: 'auto',
                    class: 'header-table'
                },
                {
                    text: this.$t('customers.name'),
                    value: 'name',
                    width: 'auto',
                    sortable: true,
                    class: 'header-table'
                }
            ]
        },
        userRender: {
            get() {
                var self = this
                var user = {}
                if (this.idUser) {
                    user = this.$store.getters['access/getAccess'](this.idUser)
                    self.selectedLocations = []
                    self.selected = []
                    if (user.restaurants.length > 0) {
                        for (var i = 0; i < user.restaurants.length; i++) {
                            self.selectedLocations.push(user.restaurants[i])
                        }
                    }

                    self.selectedLocations.forEach((location) => {
                        if (self.items[location]) {
                            self.items[location].selected = true
                        }
                        self.selected.push({ ...self.items[location] })
                    })
                }
                return user
            },
            set(value) {
                this.$store.commit('access/updateAccess', value)
            }
        }
    },
    methods: {
        load() {
            var self = this
            if (self.$route.name == 'Access') {
                if (typeof self.id != 'undefined') {
                    self.$router.push({
                        name: 'EditAccess',
                        params: { id: self.id }
                    })
                    self.idUser = self.id
                } else {
                    self.$store.dispatch('access/addNewAccess').then((id) => {
                        self.idUser = id
                        self.$router.push({ name: 'AddAccess' })
                    })
                }
            } else {
                if (typeof self.id != 'undefined') {
                    self.idUser = self.id
                } else {
                    self.$store.dispatch('access/addNewAccess').then((id) => {
                        self.idUser = id
                    })
                }
            }
        },
        goBack() {
            if (this.selected.length > 0) {
                Object.values(this.items).forEach(function (v) {
                    delete v.selected
                })
            }
            if (!this.$route.params.id) {
                this.$store.commit('access/deleteUser', this.idUser)
            }
            this.$overlay.show()
            this.$emit('back')
        },
        saveUser() {
            var self = this
            this.$store.dispatch('access/validateUser', this.idUser).then((response) => {
                if (response.status) {
                    this.errors.name = false
                    this.errors.email = false
                    this.errors.type = false

                    const restaurants = this.$refs.accessDataSelected.selection

                    if (Object.values(restaurants).length == 0) {
                        this.errors.restaurants = 'access.error_restaurants'
                        return
                    } else {
                        this.errors.restaurants = false
                    }

                    const users = this.$store.getters['access/getAccess']()
                    const loggedUser = this.$store.getters['getUserLogged']

                    if (self.userRender.email == loggedUser.email) {
                        self.errors.email = 'admin.errors.email_used'
                        return false
                    }

                    for (const user of Object.values(users)) {
                        if (user.id != 'tmpitw' && user.id != self.userRender.id && user.email == self.userRender.email) {
                            self.errors.email = 'admin.errors.email_used'
                            return false
                        }
                    }

                    self.userRender.restaurants = Object.values(restaurants)

                    var group = []
                    if (self.userRender.restaurants.length > 0) {
                        for (var i in self.userRender.restaurants) {
                            var idx = self.userRender.restaurants[i]
                            if (idx.id) {
                                group.push(idx.id)
                            }
                        }
                        self.userRender.restaurants = group.join()
                    }

                    var sendForm = self.$route.params.id ? 'editAccess' : 'addAccess'

                    self.$overlay.show()
                    self.$store.dispatch('access/' + sendForm, self.idUser).then(() => {
                        self.goBack()
                        self.$snackbar.success({
                            message: self.$route.params.id ? self.$t('snackbar.edit') : self.$t('snackbar.add'),
                            customClass: 'success',
                            duration: 5000
                        })
                    })
                } else {
                    self.errors = response.errors
                    self.$overlay.hide()
                    // scrollError()
                }
            })
        }
    },
    created() {
        const { type } = this.$store.getters['getUserLogged']
        if ([3, 4].includes(type)) {
            this.$router.push('/admin')
        }
    },
    mounted() {
        this.load()
    },
    watch: {}
}
</script>

<style lang="scss">
.form-page {
    width: 100%;
    height: calc(100% - 60px);
    padding-top: 35px;

    .row-error {
        top: 20px;
        position: relative;
    }

    .title-form {
        font-family: Speedee-bd;
        font-size: 20px;
        padding-bottom: 15px;
    }
    .description {
        font-family: Speedee;
        color: #adadad;
        font-size: 16px;
    }
    .form-actions {
        display: flex;
        flex-direction: column;
        width: 350px;
        height: auto;

        .v-select__slot {
            width: 350px;
            height: 60px;
        }
        .v-input__slot {
            margin-bottom: 0px;
        }
        .v-text-field__details {
            display: none;
        }

        .input-text {
            border: 2px solid #bebebe;
            border-radius: 4px;
            font-family: Speedee-lt;
            height: 38px;
            padding: 5px;
            margin-top: 20px;
            height: 60px;

            &.error {
                border: 2px solid $red;
                background-color: $red2;
            }

            &.disabled {
                opacity: 0.6;
                cursor: not-allowed;
            }
        }
    }
    .data-table {
        height: calc(100% - 300px);

        &.with-errors {
            max-height: 520px;
        }
        &.with-error {
            max-height: 590px;
        }
    }
    .action-buttons {
        display: flex;

        .export-button {
            background-color: $yellow;
            width: fit-content;
            padding: 8px 20px;
            height: fit-content;
            font-family: Speedee-lt;
            align-self: flex-end;
            font-size: 16px;
            border-radius: 4px;
            cursor: pointer;
            margin-right: 16px;

            &:hover {
                background-color: #e7a809;
            }
        }
        .cancel {
            background-color: #ddd;
            width: fit-content;
            padding: 8px 20px;
            height: fit-content;
            font-family: Speedee-lt;
            align-self: flex-end;
            font-size: 16px;
            border-radius: 4px;
            cursor: pointer;
            color: #333;

            &:hover {
                background-color: #9e9e9e;
            }
        }
    }
}
</style>
