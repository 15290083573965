<template>
    <div id="modal-template">
        <transition name="modal">
            <div class="modal-mask" @click="close">
                <div class="modal-wrapper">
                    <div class="modal-container" :class="type">
                        <div class="modal_description">
                            <b>{{ $t('access.warning') }}</b>
                        </div>
                        <div class="modal_description" v-html="$t('access.remove_user', { user: userName })"></div>
                        <div class="actions">
                            <div class="action cancel" @click="close">{{ $t('public.modal.cancel') }}</div>
                            <div class="action confirm" @click="confirm">{{ $t('public.modal.confirm') }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'Modal',
    props: {
        type: String,
        userName: String
    },
    methods: {
        close(event) {
            event.preventDefault()
            event.stopPropagation()
            this.$emit('close')
        },
        confirm(event) {
            event.preventDefault()
            event.stopPropagation()
            this.$emit('confirm')
        }
    }
}
</script>

<style lang="scss">
#modal-template {
    .modal-mask {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .modal-wrapper {
            position: relative;

            .modal-container {
                height: 350px;
                width: 600px;
                margin: 0px auto;
                background-color: #eee;
                border-radius: 4px;
                box-shadow: 0px 4px 5px 1px rgba(0, 0, 0, 0.15);
                background-position: top 20px center;
                background-repeat: no-repeat;
                display: flex;
                flex-wrap: wrap;
                padding-top: 140px;
                padding-bottom: 5px;
                background-position: top 20px center;
                background-repeat: no-repeat;
                background-image: url('../../../../../public/img/popup_delete.svg');

                .modal_text {
                    display: block;
                }

                .modal_description {
                    font-family: Speedee;
                    font-size: 20px;
                    display: block;
                    width: 100%;
                    text-align: center;

                    b {
                        font-weight: bold;
                    }
                }

                .actions {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-around;

                    .action {
                        border-radius: 4px;
                        font-size: 20px;
                        font-family: Speedee-bd;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        padding: 15px;
                        &.close,
                        &.confirm {
                            background-color: #e63946;
                            color: #fff;
                        }

                        &.cancel {
                            background-color: #ddd;
                        }
                    }
                }
            }
        }
    }
}
</style>
