<template>
    <div class="layout-page">
        <template v-if="form">
            <Form :id="userID" @back="closeForm"></Form>
        </template>
        <template v-else>
            <div class="restaurant-container">
                <div class="your-restaurants">
                    {{ $t('access.users_with_access') }}
                </div>
                <div class="search">
                    <template v-if="![3, 4].includes(userLogged.type)">
                        <div class="export-button" @click="addAccess()">{{ $t('access.create_user') }}</div>
                    </template>
                    <input type="text" v-model="search" class="input-search" name="name" />
                </div>
            </div>
            <div class="data-table">
                <v-data-table v-if="items" disable-pagination v-model="selected" :headers="headers" fixed-header :search="search" :items="Object.values(items)" sort-by="name" :sort-asc="true" :hide-default-footer="true">
                    <template #item.name="item">
                        <div class="row-name">{{ item.item.name }}</div>
                    </template>
                    <template #item.email="item">
                        <div class="row-email">{{ item.item.email }}</div>
                    </template>
                    <template #item.type="item">
                        <div class="row-name" v-if="item.item.type == 1">{{ $t('access_one') }}</div>
                        <div class="row-name" v-else-if="item.item.type == 2">{{ $t('access_two') }}</div>
                        <div class="row-name" v-else-if="item.item.type == 3">{{ $t('access_three') }}</div>
                        <div class="row-name" v-else-if="item.item.type == 4">{{ $t('access_four') }}</div>
                    </template>
                    <template #item.restaurants="item">
                        <div class="restaurant" v-if="item.item.restaurants.length == 0">{{ $t('access_no_restaurant') }}</div>
                        <div class="row-restaurants" v-else>
                            <div class="restaurants" v-for="(restaurants, index) in item.item.restaurants" :key="restaurants.id">
                                <template v-if="typeof getRestaurants(restaurants) !== 'undefined'">
                                    <span class="bold">{{ getRestaurants(restaurants).code }}</span>
                                    {{ getRestaurants(restaurants).name }}
                                    <span class="comma" v-if="index < item.item.restaurants.length - 1">,</span>
                                </template>
                            </div>
                        </div>
                    </template>
                    <template #item.id="item">
                        <div class="row actions">
                            <div class="row-image" @click="goToReport(item.item.id)" v-if="item.item.type == 3 || item.item.type == 4"><img class="icon" src="../../public/img/pen.svg" alt="edit" /></div>
                            <div class="row-image" @click="showConfirmPopup(item.item.id)"><img class="icon" src="../../public/img/delete.svg" alt="delete" /></div>
                        </div>
                    </template>
                    <template v-slot:no-data>
                        <div class="no-data">
                            {{ $t('bills.table_no_data') }}
                        </div>
                    </template>
                </v-data-table>
                <div class="info" v-html="$t('access.table_info')"></div>
            </div>
            <Modal v-if="showPopup" :userName="items[removeUserId].name" v-on:close="closePopup()" v-on:confirm="removeUser()"></Modal>
        </template>
    </div>
</template>

<script>
import Form from '../components/layout/admin/access/Form.vue'
import Modal from '../components/layout/admin/access/Modal.vue'

export default {
    components: { Form, Modal },
    name: 'Access',
    data() {
        return {
            search: '',
            pagination: {
                page: 1,
                itemsPerPage: 20
            },
            exportButton: false,
            valueFilter: 1,
            rowsPerPage: [10, 20, 50],
            pageCount: 0,
            selected: [],
            maxPageVisibles: 10,
            userID: false,
            form: false,
            showPopup: false,
            removeUserId: -1
        }
    },
    computed: {
        items() {
            var items = this.$store.getters['access/getAccess']()
            return items
        },
        headers() {
            return [
                {
                    text: this.$t('access.name'),
                    align: 'start',
                    sortable: true,
                    value: 'name',
                    width: 'auto',
                    class: 'header-table'
                },
                {
                    text: this.$t('access.email'),
                    value: 'email',
                    width: 'auto',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: this.$t('access.role'),
                    value: 'type',
                    align: 'start',
                    width: 'auto',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: this.$t('access.restaurant'),
                    value: 'restaurants',
                    align: 'start',
                    width: 'auto',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: '',
                    value: 'id',
                    align: 'start',
                    width: '120px',
                    sortable: true,
                    class: 'header-table'
                }
            ]
        },
        nRegistros() {
            var letter = this.$t('bills.show_bills')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        pages() {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
                return 0
            }

            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        },
        userLogged() {
            return this.$store.getters['getUserLogged']
        }
    },
    methods: {
        load() {
            var self = this
            self.$overlay.show()
            this.$store.dispatch('restaurant/loadRestaurants').then(function () {
                self.$store.dispatch('access/loadAccess').then(function () {
                    if (self.$route.params.id) {
                        self.goToReport(self.$route.params.id)
                    } else if (self.$route.name == 'AddAccess') {
                        self.addAccess()
                    } else {
                        self.form = false
                    }
                    self.$overlay.hide()
                })
            })
        },
        getRestaurants(id) {
            return this.$store.getters['restaurant/getRestaurants'](id)
        },
        closeForm() {
            this.form = false
            this.userID = false
            this.$router.push({
                name: 'Access'
            })
            this.$overlay.hide()
        },
        goToReport(id) {
            this.form = true
            this.userID = id
        },
        addAccess() {
            this.form = true
            this.userID = undefined
        },
        showConfirmPopup(id) {
            this.showPopup = true
            this.removeUserId = id
        },
        closePopup() {
            this.showPopup = false
            this.removeUserId = -1
        },
        removeUser() {
            const self = this
            this.$store.dispatch('access/removeUser', { id: this.removeUserId }).then(function () {
                self.closePopup()
                self.load()
            })
        }
    },
    created() {
        const { type } = this.$store.getters['getUserLogged']
        if ([3, 4].includes(type)) {
            this.$router.push('/admin')
            return
        }
        this.load()
    }
}
</script>

<style lang="scss">
.restaurant-container {
    .search {
        .input-search {
            background-image: url('../../public/img/searcher.svg');
            margin-top: unset;
        }

        .export-button {
            background-color: $yellow;
            width: fit-content;
            padding: 8px 20px;
            height: fit-content;
            font-family: Speedee-lt;
            align-self: flex-end;
            margin-left: auto;
            font-size: 16px;
            border-radius: 4px;
            margin-right: 30px;
            cursor: pointer;
            align-self: flex-start;

            &:hover {
                background-color: #e7a809;
            }
        }
    }
}
.data-table {
    .info {
        .text {
            margin-top: 30px;
            color: #3f3e3e;
            font-family: Speedee-lt;
            display: contents;

            a {
                color: #3f3e3e;
                font-family: Speedee-lt;
                text-decoration: none;
            }
        }
    }

    .actions {
        .row-image {
            display: inline-block;
        }
    }
}
</style>
